import { IState } from "../../utils/initial-state";
import { ACTION_TYPE, IActions } from "../actions/profile-actions";
import React from "react";

// not sure why IState, IAction not working inside React.Reducer<>

// @ts-ignore
export const profileReducer: React.Reducer<IState, IActions> = (
  state: IState,
  action: IActions
) => {
  switch (action.type) {
    case ACTION_TYPE.SET_ERROR:
      if (action.payload.error) {
        return {
          ...state,
          error: action.payload.error,
        };
      }

      return {
        ...state,
        error: undefined,
      };
      break;
    case ACTION_TYPE.SET_ACTIVE_PROFILE:
      if (state?.activeProfile && action.payload.profile) {
        return {
          ...state,
          activeProfile: action.payload.profile,
        };
      }
      return {
        ...state,
        activeProfile: undefined,
      };
      break;
    case ACTION_TYPE.ADD_PROFILE:
      let currentProfiles;
      if (state?.profiles && action.payload?.profile) {
        currentProfiles = state.profiles.concat(action.payload.profile);
      }
      return {
        ...state,
        profiles: currentProfiles,
      };
      break;
    case ACTION_TYPE.SET_PROFILES:
      if (action.payload?.profiles) {
        return {
          ...state,
          profiles: action.payload.profiles,
        };
      }
      // no payload. set to undefined
      return {
        ...state,
        profiles: undefined,
      };
      break;

    case ACTION_TYPE.EDIT_PROFILE:
      // locate item index we will update
      const existingProfileIndex = (state?.profiles || []).findIndex(
        (profile) => profile.id === action.payload.profileId
      );
      const profiles = state?.profiles || [];
      let updatedProfiles;

      if (profiles && existingProfileIndex > -1) {
        const profileUpdates = action.payload.updates;
        updatedProfiles = profiles;
        if (existingProfileIndex > -1 && updatedProfiles && profileUpdates) {
          updatedProfiles[existingProfileIndex] = profileUpdates;
        }

        return {
          ...state,
          profiles: updatedProfiles,
        };
      }
      return state;
      break;

    case ACTION_TYPE.SET_SKILL:
      // locate skill profile
      const profileIndex = (state?.profiles || []).findIndex(
        (p) => p.id === action.payload.profileId
      );
      const profileSkills = (state?.profiles || []).find(
        (p) => p.id === action.payload.profileId
      )?.skills;

      if (profileSkills && profileIndex > -1) {
        const profiles = state.profiles;
        // locate skill to update
        const skillIndex = profileSkills.findIndex(
          (s) => s.id === action.payload.skillId
        );

        if (skillIndex > -1 && action.payload.skillUpdates) {
          profileSkills[skillIndex] = action.payload.skillUpdates;
          profiles[profileIndex].skills = profileSkills;
        }

        return {
          ...state,
          profiles: profiles,
        };
      } else {
        return { ...state };
      }
      break;
    case ACTION_TYPE.SET_PROJECT:
      // locate profile
      const pIndex = (state?.profiles || []).findIndex(
        (p) => p.id === action.payload.profileId
      );
      const profileProjects = (state?.profiles || []).find(
        (p) => p.id === action.payload.profileId
      )?.projects;

      // if we located profile index and have projects continue
      if (pIndex > -1 && profileProjects) {
        const profilesForBlock = state.profiles;
        // locate project to update
        const projectIndex = profileProjects.findIndex(
          (p) => p.id === action.payload.projectUpdates?.id
        );
        if (projectIndex > -1 && action.payload.projectUpdates) {
          profileProjects[projectIndex] = action.payload.projectUpdates;
          profilesForBlock[pIndex].projects = profileProjects;
        }

        return {
          ...state,
          profiles: profilesForBlock,
        };
      } else {
        return { ...state };
      }
      break;
    case ACTION_TYPE.SET_RESUME:
      // locate skill profile
      const profileIdx = (state?.profiles || []).findIndex(
        (p) => p.id === action.payload.profileId
      );
      const profileResumes = (state?.profiles || []).find(
        (p) => p.id === action.payload.profileId
      )?.resumes;

      if (profileResumes && profileIdx > -1) {
        const profiles = state.profiles;
        // locate skill to update
        const resumeIndex = profileResumes.findIndex(
          (s) => s.id === action.payload.resumeUpdates?.id
        );

        if (resumeIndex > -1 && action.payload.resumeUpdates) {
          profileResumes[resumeIndex] = action.payload.resumeUpdates;
          profiles[profileIdx].resumes = profileResumes;
        }

        return {
          ...state,
          profiles: profiles,
        };
      } else {
        return { ...state };
      }
      break;
  }
};
