import { useCallback, useState } from "react";
import { ProfileType } from "../types/profile";
import UrlHelper from "../utils/UrlHelper";

const useActiveProfile = () => {
  // hooks state and variables
  const [activeProfileResponse, setActiveProfileResponse] = useState<
    ProfileType | undefined
  >(undefined);
  const [activeProfileLoadingStatus, setActiveProfileLoadingStatus] =
    useState(true);
  const [activeProfileErrorStatus, setActiveProfileErrorStatus] =
    useState(false);

  // fetching logic
  const fetchActiveProfile = useCallback(async () => {
    try {
      const url = `${UrlHelper.getBaseUrl()}/profile/active`;
      const response = await fetch(url);

      if (response.ok) {
        setActiveProfileResponse(await response.json());
        setActiveProfileLoadingStatus(false);
      } else {
        setActiveProfileLoadingStatus(false);
        setActiveProfileErrorStatus(true);
      }
    } catch (error) {
      console.log("error inside useActiveProfile hook");
      console.error(error);
      setActiveProfileErrorStatus(true);
      setActiveProfileLoadingStatus(false);
    }
  }, []);

  return {
    activeProfileErrorStatus,
    activeProfileResponse,
    activeProfileLoadingStatus,
    fetchActiveProfile,
  };
};

export default useActiveProfile;
