import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Row } from "react-bootstrap";
import { Link, useSubmit } from "react-router-dom";

const AdminNavigation = () => {
  const submit = useSubmit();
  const logoutHandler = () => {
    submit(null, { action: "/logout", method: "post" });
  };

  return (
    <Container fluid>
      <Row>
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="/admin">ProjectsByMiguel.com</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/admin">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/profile">
                  Profiles
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/user">
                  Users
                </Nav.Link>
                <Button onClick={logoutHandler}>Logout</Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
    </Container>
  );
};

export default AdminNavigation;
