import React from "react";
import { UserType } from "../types/user";
import { Accordion } from "react-bootstrap";
import { User } from "./User";

export const Users: React.FC<{ users: UserType[] | undefined }> = ({
  users,
}) => {
  console.log(users);
  return (
    <div>
      <Accordion>
        {users?.map((user) => (
          <User user={user} key={user.id} />
        ))}
      </Accordion>
    </div>
  );
};
