import { IState } from "./initial-state";

export interface ILocalStorage extends IState {
  ttl: number;
}

export class LocalStorageHelper {
  private static KEY = "state";
  private constructor() {}

  public static saveToLocalStorage(state: IState): void {
    // we need to append a ttl to be used when retrieving.
    const now = new Date();
    const ttl = now.getTime() + 1800000; // 30 min ttl
    const updateState: ILocalStorage = { ...state, ttl: ttl };
    localStorage.setItem(LocalStorageHelper.KEY, JSON.stringify(updateState));
  }

  public static loadFromLocalStorage(): IState | undefined {
    const data: string | null = localStorage.getItem(LocalStorageHelper.KEY);
    const obj = data ? JSON.parse(data) : undefined;
    const now = new Date();

    if (!obj) return undefined;

    if (obj && now.getTime() > obj.ttl) {
      // clear local storage expired
      console.log("expired");
      localStorage.removeItem(LocalStorageHelper.KEY);
      return undefined;
    }

    const state = JSON.parse(JSON.stringify(obj));
    delete state["ttl"];
    return state;
  }

  public static clearLocalStorage(): void {
    localStorage.removeItem(LocalStorageHelper.KEY);
  }
}
