import React from "react";
import { ProjectType } from "../types/project";
import { Accordion, Badge, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

export interface ProjectsAccordionInterface {
  projects: ProjectType[];
  profileId: string;
}

const ProjectsAccordion: React.FC<ProjectsAccordionInterface> = ({
  projects,
  profileId,
}) => {
  return (
    <div>
      {projects && (
        <Accordion>
          {projects.map((project) => (
            <Accordion.Item eventKey={`${project.id}`} key={project.id}>
              <Accordion.Header>
                {project.title} -
                <Badge bg="secondary">
                  {project && project.active === 1 ? "Active" : "Disabled"}
                </Badge>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  <ListGroup.Item>
                    <b>Title:</b>
                    <p>{project.title}</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Description:</b>
                    <p>{project.description}</p>
                  </ListGroup.Item>
                  {project.url && (
                    <ListGroup.Item>
                      <b>URL: </b>
                      <a href={project.url}>{project.url}</a>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item>
                    <Link
                      className="btn btn-dark"
                      to={`/admin/profile/${profileId}/project/${project.id}`}
                    >
                      Edit Project
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
      <br />
      <Link
        className="btn btn-secondary"
        to={`/admin/profile/${profileId}/project`}
      >
        Add Projects
      </Link>
    </div>
  );
};
export default ProjectsAccordion;
