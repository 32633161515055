import {
  Alert,
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap";
import React from "react";
import {
  ActionFunction,
  json,
  Link,
  redirect,
  useActionData,
  useNavigation,
  useParams,
} from "react-router-dom";
import UrlHelper from "../utils/UrlHelper";
import { Form as RouterDomForm } from "react-router-dom";
import { ProjectType } from "../types/project";
import { AuthHelper } from "../utils/AuthHelper";

export interface ProjectFormInterface {
  project: ProjectType | null;
  method: string;
}

const ProjectForm: React.FC<{
  project: ProjectType | undefined;
  method: string;
}> = ({ project, method }) => {
  // get action data.
  const data = useActionData() as { message: string[] };
  const params = useParams();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  let btnText = method.toLowerCase() === "post" ? "Add" : "Update";

  if (isSubmitting) {
    btnText = "Submitting...";
  }

  return (
    <Row>
      {data && data?.message && (
        <Alert variant="danger">
          {data.message.map((err) => (
            <p>{err}</p>
          ))}
        </Alert>
      )}
      {isSubmitting && (
        <Spinner animation="border" role="status">
          <p className="visually-hidden">Submitting....</p>
        </Spinner>
      )}
      {!isSubmitting && (
        <Form method={method} as={RouterDomForm} style={{ color: "#fff" }}>
          <FormGroup className="mb-3">
            <Form.Label htmlFor="title">Title</Form.Label>
            <FormControl
              type="text"
              placeholder="Enter Title"
              name="title"
              defaultValue={project ? project.title : ""}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="description">Description</FormLabel>
            <Form.Control
              as="textarea"
              rows={6}
              name="description"
              defaultValue={project ? project.description : ""}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="url">Url</FormLabel>
            <FormControl
              type="text"
              name="url"
              placeholder="Enter url (Optional)"
              defaultValue={project && project.url ? project.url : ""}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Select
              aria-label="Status"
              defaultValue={project ? project.active : ""}
              name="active"
            >
              <option value="0">Disabled</option>
              <option value="1">Active</option>
            </Form.Select>
          </FormGroup>
          <FormGroup>
            <Button type="submit" disabled={isSubmitting}>
              {btnText}
            </Button>{" "}
            <Link
              className="btn btn-secondary"
              to={`/admin/profile/${params.profileId}`}
            >
              Back
            </Link>
          </FormGroup>
        </Form>
      )}
    </Row>
  );
};

export default ProjectForm;

export const projectAction: ActionFunction = async ({ request, params }) => {
  const formData = await request.formData();
  const method = request.method;
  const profileId = params.profileId;
  const token = AuthHelper.getAuthToken();
  let url = `${UrlHelper.getBaseUrl()}/profile/${profileId}/project`;
  const projectBody = {
    title: formData.get("title"),
    description: formData.get("description"),
    url: formData.get("url") !== "" ? formData.get("url") : null,
    active: formData.get("active") === "1",
  };

  if (method.toLowerCase() === "patch") {
    const projectId = params.projectId;
    url = `${UrlHelper.getBaseUrl()}/profile/${profileId}/project/${projectId}`;
  }

  // fire off fetch
  const response = await fetch(url, {
    method: method,
    body: JSON.stringify(projectBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  if (response.status === 401) {
    throw json(
      { message: "Authorization required to be able to make request" },
      { status: 401 }
    );
  } else if (response.status === 400) {
    return response;
  } else if (!response.ok) {
    throw json({ message: "Request could not be fulfilled" }, { status: 500 });
  } else {
    return response;
    //return redirect(`/admin/profile/${profileId}`);
  }
};
