import React from "react";
import Image from "react-bootstrap/Image";
import styles from "./Picture.module.css";
//import img from "../../assets/miguel_huerta_headshot.jpg";
import img2 from "../../assets/IMG_5098.jpg";

const Picture: React.FC = () => {
  return (
    <div className={styles.container}>
      <Image src={img2} thumbnail />
    </div>
  );
};

export default Picture;
