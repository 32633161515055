import { ListGroup, Row } from "react-bootstrap";
import React from "react";
import { SkillsInterface } from "./SkillListForm";

const SkillsList: React.FC<SkillsInterface> = ({ skills }) => {
  return (
    <Row>
      <ListGroup>
        {skills.map((skill) => (
          <ListGroup.Item key={skill.id}>{skill.title}</ListGroup.Item>
        ))}
      </ListGroup>
    </Row>
  );
};

export default SkillsList;
