import React, { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import ResumeForm from "../../components/ResumeForm";
import { useActionData, useNavigate, useParams } from "react-router-dom";
import { ResumeType } from "../../types/resume";
import ProfileContext from "../../context/profile-context";

const AddResumePage: React.FC = () => {
  const resumeActionData = useActionData() as ResumeType;
  const profileCtx = useContext(ProfileContext);
  const { profileId } = useParams() as { profileId: string };
  const navigate = useNavigate();

  useEffect(() => {
    if (resumeActionData) {
      profileCtx.setResume(parseInt(profileId), resumeActionData);
      navigate(`/admin/profile/${profileId}`);
    }
  }, [resumeActionData]);
  return (
    <Container>
      <Row className="mb-3">
        <h4>Add Resume to Profile</h4>
      </Row>
      <ResumeForm method="post" resume={undefined} />
    </Container>
  );
};

export default AddResumePage;
