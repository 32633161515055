import React from "react";
import { ListGroup } from "react-bootstrap";
import { SkillType } from "../types/skill";
import SkillItemForm from "./SkillItemForm";

export interface SkillsInterface {
  skills: SkillType[];
}

const SkillListForm: React.FC<SkillsInterface> = ({ skills }) => {
  return (
    <>
      <ListGroup>
        {skills.map((skill) => (
          <SkillItemForm skill={skill} method="patch" key={skill.id} />
        ))}
        <SkillItemForm skill={null} method="post" />
      </ListGroup>
    </>
  );
};

export default SkillListForm;
