import { useCallback, useState } from "react";
import { ProfileType } from "../types/profile";
import UrlHelper from "../utils/UrlHelper";

const useGetProfiles = () => {
  // states
  const [profilesResponse, setProfilesResponse] = useState<
    ProfileType[] | undefined
  >(undefined);
  const [profileLoadingStatus, setProfileLoadingStatus] = useState(true);
  const [profileErrorStatus, setProfileErrorStatus] = useState(false);

  // fetching logic
  const fetchProfiles = useCallback(async () => {
    try {
      const response = await fetch(`${UrlHelper.getBaseUrl()}/profile`);
      if (response.ok) {
        setProfilesResponse(await response.json());
        setProfileLoadingStatus(false);
      } else {
        setProfileErrorStatus(true);
        setProfileLoadingStatus(false);
      }
    } catch (error) {
      console.log(`Error inside useGetProfiles: `, error);
      setProfileErrorStatus(true);
      setProfileLoadingStatus(false);
    }
  }, []);

  return {
    profileErrorStatus,
    profilesResponse,
    profileLoadingStatus,
    fetchProfiles,
  };
};
export default useGetProfiles;
