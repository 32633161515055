import Container from "react-bootstrap/Container";
import { Row, Spinner } from "react-bootstrap";
import ProjectForm from "../../components/ProjectForm";
import { useActionData, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ProfileContext from "../../context/profile-context";
import { ProfileType } from "../../types/profile";
import { ProjectType } from "../../types/project";
import { LocalStorageHelper } from "../../utils/LocalStorageHelper";

const EditProjectPage: React.FC = () => {
  const profileCtx = useContext(ProfileContext);
  const { profileId, projectId } = useParams() as {
    profileId: string;
    projectId: string;
  };
  const navigate = useNavigate();
  const [profile, setProfile] = useState<ProfileType | undefined>(undefined);
  const [project, setProject] = useState<ProjectType | undefined>(undefined);
  const actionData = useActionData();

  useEffect(() => {
    if (actionData) {
      profileCtx.setProject(parseInt(profileId), actionData as ProjectType);
      // save and redirect to profile page
      navigate(`/admin/profile/${profileId}`);
    }
  }, [actionData]);

  useEffect(() => {
    const foundProfile = profileCtx.state.profiles.find(
      (p) => p.id === parseInt(profileId)
    );

    if (!foundProfile) {
      profileCtx.setError({
        title: "An error occurred!",
        message: "No matching profile found",
        statusCode: 400,
      });
      navigate("/admin/error");
    }

    if (foundProfile) setProfile(foundProfile);
  }, []);

  useEffect(() => {
    if (profile) {
      const foundProject = profile?.projects.find(
        (prj) => prj.id === parseInt(projectId)
      );

      if (foundProject) setProject(foundProject);

      if (!foundProject) {
        profileCtx.setError({
          title: "An error occurred!",
          message: "No matching project found",
          statusCode: 400,
        });
        navigate("/admin/error");
      }
    }
  }, [profile]);

  return (
    <>
      {project && (
        <Container>
          <Row>
            <h4>Edit Project</h4>
          </Row>
          <ProjectForm project={project} method="patch" />
        </Container>
      )}
      {!project && (
        <Container fluid style={{ margin: "5% 45%" }}>
          <Row style={{ margin: "20px 0", textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
        </Container>
      )}
    </>
  );
};

export default EditProjectPage;
