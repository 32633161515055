import {
  Outlet,
  redirect,
  useLoaderData,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import AdminNavigation from "../components/Nav/AdminNavigation";
import Container from "react-bootstrap/Container";
import { useContext, useEffect } from "react";
import { AuthHelper } from "../utils/AuthHelper";
import useAuthToken from "../hooks/useAuthToken";
import ProfileContext from "../context/profile-context";

const AdminLayout = () => {
  // check token
  //const token = useLoaderData();
  const navigate = useNavigate();
  const profileCtx = useContext(ProfileContext);
  const { tokenErrorStatus, tokenResponse, tokenLoadingStatus, fetchToken } =
    useAuthToken();
  const submit = useSubmit();

  useEffect(() => {
    fetchToken();
  }, []);

  useEffect(() => {
    if (tokenResponse && tokenResponse === "EXPIRED") {
      submit(null, { action: "/logout", method: "post" });
    }

    const tokenDuration = AuthHelper.getTokenDuration();

    setTimeout(() => {
      // fire off submit call
      submit(null, { action: "/logout", method: "post" });
    }, tokenDuration);
  }, [tokenResponse, submit]);

  useEffect(() => {
    if (tokenErrorStatus) {
      navigate("/login");
    }
  }, [tokenErrorStatus]);

  return (
    <>
      <AdminNavigation />
      <Container fluid="lg">
        <main>
          <Outlet />
        </main>
      </Container>
    </>
  );
};

export default AdminLayout;
