import React from "react";
import { ResumeType } from "../types/resume";
import { ListGroupItem } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

export interface ResuleListItemInterface {
  resume: ResumeType;
}

const ResumeListItem: React.FC<ResuleListItemInterface> = ({ resume }) => {
  const params = useParams();
  return (
    <ListGroupItem key={`${resume.id}`}>
      <b>Url:</b>
      {resume.url}
      <div>
        <Link
          className="btn btn-dark"
          to={`/admin/profile/${params.profileId}/resume/${resume.id}`}
        >
          Edit
        </Link>
      </div>
    </ListGroupItem>
  );
};

export default ResumeListItem;
