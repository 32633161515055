import { createBrowserRouter } from "react-router-dom";
import AdminLayout from "../layouts/Admin";
import Error from "../pages/Error";
import Home from "../pages/Admin/Home";
import ProfilesPage from "../pages/Admin/Profiles";
import EditProfilePage from "../pages/Admin/EditProfile";
import { manipulateProfileAction } from "../components/ProfileForm";
import { AuthHelper } from "../utils/AuthHelper";
import AuthenticationPage from "../pages/Admin/Authentication";
import { authFormAction } from "../components/AuthForm";
import { logoutAction } from "../pages/Admin/Logout";
import AddProfilePage from "../pages/Admin/AddProfile";
import EditSkillsPage from "../pages/Admin/EditSkills";
import { skillFormAction } from "../components/SkillItemForm";
import AddProjectPage from "../pages/Admin/AddProject";
import { projectAction } from "../components/ProjectForm";
import EditProjectPage from "../pages/Admin/EditProject";
import AddResumePage from "../pages/Admin/AddResume";
import { resumeAction } from "../components/ResumeForm";
import EditResumePage from "../pages/Admin/EditResume";
import MainHomePage from "../pages/Home";
import ErrorComponent from "../components/ErrorComponent";
import UsersPage from "../pages/Admin/Users";

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        element: <MainHomePage />,
      },
      {
        path: "error",
        element: <ErrorComponent />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "profile",
        element: <ProfilesPage />,
      },
      {
        path: "profile/add",
        element: <AddProfilePage />,
        action: manipulateProfileAction,
      },
      {
        path: "profile/:profileId",
        element: <EditProfilePage />,
        action: manipulateProfileAction,
      },
      {
        path: "profile/:profileId/skill",
        element: <EditSkillsPage />,
        action: skillFormAction,
      },
      {
        path: "profile/:profileId/project",
        element: <AddProjectPage />,
        action: projectAction,
      },
      {
        path: "profile/:profileId/project/:projectId",
        element: <EditProjectPage />,
        action: projectAction,
      },
      {
        path: "profile/:profileId/resume",
        element: <AddResumePage />,
        action: resumeAction,
      },
      {
        path: "profile/:profileId/resume/:resumeId",
        element: <EditResumePage />,
        action: resumeAction,
      },
      {
        path: "error",
        element: <ErrorComponent />,
      },
      {
        path: "user",
        element: <UsersPage />,
      },
    ],
  },
  {
    path: "login",
    element: <AuthenticationPage />,
    action: authFormAction,
  },
  {
    path: "logout",
    action: logoutAction,
  },
]);

export default router;
