import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import React, { ReactNode } from "react";
import { Footer } from "../Main/Footer";

export interface PageInterface {
  children: ReactNode;
}

const PageLayout: React.FC<PageInterface> = ({ children }) => {
  // @TODO set the theme manually. Nicer option would be a context to set theme. Will do it later
  document.body.style.backgroundColor = "#282c34";
  return (
    <Container>
      <Row className="justify-content-sm-center" style={{ margin: "50px 0" }}>
        <Col lg={6} md={12} sm={12}>
          {children}
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default PageLayout;
