import React from "react";
import "./App.css";
import router from "./router";
import { RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import ProfileProvider from "./store/providers/ProfileProvider";

function App() {
  return (
    <ProfileProvider>
      <RouterProvider router={router} />
    </ProfileProvider>
  );
}

export default App;
