import React from "react";
import styles from "./Footer.module.css";
export const Footer: React.FC = () => {
  return (
    <footer className={`${styles.footer} ${styles["blue-theme"]}`}>
      <div>
        <span>&copy; </span>
        <span>Miguel Huerta. </span>
        <span>All Rights Reserved</span>
      </div>
    </footer>
  );
};
