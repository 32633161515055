import React, { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import ResumeForm from "../../components/ResumeForm";
import { useActionData, useNavigate, useParams } from "react-router-dom";
import ProfileContext from "../../context/profile-context";
import { ResumeType } from "../../types/resume";

const EditResumePage: React.FC = () => {
  const profileCtx = useContext(ProfileContext);
  const { profileId, resumeId } = useParams() as {
    profileId: string;
    resumeId: string;
  };
  const navigate = useNavigate();
  const profile = profileCtx.state.profiles.find(
    (p) => p.id === parseInt(profileId)
  );
  const resumeActionData = useActionData() as ResumeType;

  useEffect(() => {
    if (resumeActionData) {
      profileCtx.setResume(parseInt(profileId), resumeActionData);
      navigate(`/admin/profile/${profileId}`);
    }
  }, [resumeActionData]);

  if (!profile) {
    profileCtx.setError({
      title: "An error occurred!",
      message: "No matching profile found",
      statusCode: 400,
    });
    navigate("error");
  }
  const resume = profile?.resumes.find((res) => res.id === parseInt(resumeId));

  if (!resume) {
    profileCtx.setError({
      title: "An error occurred!",
      message: "No matching resume found",
      statusCode: 400,
    });
    navigate("error");
  }
  return (
    <Container>
      <Row className="mb-3" style={{ color: "#fff" }}>
        <h4>Edit Resume</h4>
      </Row>
      <ResumeForm method="patch" resume={resume} />
    </Container>
  );
};

export default EditResumePage;
