import React from "react";
import { SkillType } from "../types/skill";
import { Button, Form, InputGroup, ListGroup } from "react-bootstrap";
import { ActionFunction, json } from "react-router-dom";
import { Form as RouterDomForm } from "react-router-dom";
import UrlHelper from "../utils/UrlHelper";
import { AuthHelper } from "../utils/AuthHelper";

export interface SkillItemFormInterface {
  skill: SkillType | null;
  method: string;
}

const SkillItemForm: React.FC<SkillItemFormInterface> = ({ skill, method }) => {
  const isPost = method.toLowerCase() === "post";

  return (
    <ListGroup.Item key={skill ? skill.id : ""}>
      <Form as={RouterDomForm} method={method}>
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="title"
            defaultValue={skill ? skill.title : ""}
            name="title"
          />
          <Form.Select
            name="active"
            aria-label="Status"
            defaultValue={skill ? skill.active : ""}
          >
            <option>Status</option>
            <option value="0">Disabled</option>
            <option value="1">Active</option>
          </Form.Select>
          <Form.Control
            name="skillId"
            defaultValue={skill ? skill.id : ""}
            hidden
          />
          <Button variant="primary" type="submit">
            {isPost ? "Add" : "Update"}
          </Button>
        </InputGroup>
      </Form>
    </ListGroup.Item>
  );
};

export default SkillItemForm;

export const skillFormAction: ActionFunction = async ({ request, params }) => {
  const method = request.method;
  const profileId = params.profileId;
  const formData = await request.formData();
  let url = `${UrlHelper.getBaseUrl()}/profile/${profileId}/skill`;
  const token = AuthHelper.getAuthToken();
  const skillData = {
    title: formData.get("title"),
    active: formData.get("active") === "1",
  };

  if (method === "PATCH") {
    const skillId = formData.get("skillId");
    url = `${UrlHelper.getBaseUrl()}/profile/${profileId}/skill/${skillId}`;
  }

  // try to make request
  const response = await fetch(url, {
    method: method,
    body: JSON.stringify(skillData),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  // check for 401
  if (response.status === 401) {
    throw json({ message: response.statusText }, { status: 401 });
  }

  if (response.status === 400) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: "Action could not be completed" }, { status: 500 });
  }

  return response;
};
