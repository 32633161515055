import React from "react";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import AuthForm from "../../components/AuthForm";

const AuthenticationPage: React.FC = () => {
  return (
    <Container fluid>
      <Row style={{ margin: "10% auto 0 auto", width: "400px", color: "#fff" }}>
        <h3>User Login</h3>
      </Row>
      <AuthForm />
    </Container>
  );
};

export default AuthenticationPage;
