import React from "react";
import { ResumeType } from "../types/resume";
import { ListGroup } from "react-bootstrap";
import ResumeListItem from "./ResumeListItem";
import { Link, useParams } from "react-router-dom";

export interface ResumesListInterface {
  resumes: ResumeType[];
}

const ResumesList: React.FC<ResumesListInterface> = ({ resumes }) => {
  const params = useParams();
  return (
    <ListGroup>
      {resumes.map((resume) => (
        <ResumeListItem resume={resume} key={resume.id} />
      ))}
      {resumes.length === 0 && (
        <ListGroup.Item>
          <Link
            className="btn btn-dark"
            to={`/admin/profile/${params.profileId}/resume`}
          >
            Add Resume
          </Link>
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default ResumesList;
