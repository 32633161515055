import { ProfileType } from "../types/profile";
import { Themes } from "../types/theme";
import { ErrorType } from "../types/error";

export interface IState {
  profiles: ProfileType[];
  activeProfile: ProfileType;
  theme: Themes;
  error: ErrorType | undefined;
}

export const initialState: IState = {
  profiles: [],
  activeProfile: {} as ProfileType,
  theme: Themes.DEFAULT,
  error: undefined,
} as IState;
