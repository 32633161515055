import React from "react";
import { ProfileType } from "../types/profile";
import { Accordion, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
export interface ProfilePageInterface {
  profile: ProfileType;
}

const Profile: React.FC<ProfilePageInterface> = ({ profile }) => {
  const status = profile.active ? "Active" : "Disabled";

  return (
    <Accordion.Item eventKey={`${profile.id}`} key={profile.id}>
      <Accordion.Header>
        <h6>
          <span>{profile.name}</span>{" "}
          <span>updated on {new Date(profile.updatedAt).toDateString()}</span>
          {"  "}
          <b>Status: {status}</b>
        </h6>
      </Accordion.Header>
      <Accordion.Body>
        <ListGroup>
          <ListGroup.Item>
            <b>Headline:</b>
            {profile.headline}
          </ListGroup.Item>
          <ListGroup.Item>
            <b>About:</b>
            {profile.about}
          </ListGroup.Item>
          <ListGroup.Item>
            <b>Created At:</b> {new Date(profile.createdAt).toDateString()}
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to={`/admin/profile/${profile.id}`}>Edit Profile</Link>
          </ListGroup.Item>
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default Profile;
