import React, { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { Link, useActionData, useNavigate, useParams } from "react-router-dom";
import styles from "./EditSkillsPage.module.css";
import SkillListForm from "../../components/SkillListForm";
import ProfileContext from "../../context/profile-context";
import { SkillType } from "../../types/skill";
import { LocalStorageHelper } from "../../utils/LocalStorageHelper";

const EditSkillsPage: React.FC = () => {
  const { profileId } = useParams() as { profileId: string };
  const profileCtx = useContext(ProfileContext);
  let profile = profileCtx.state?.profiles.find(
    (p) => p.id === parseInt(profileId)
  );
  const navigate = useNavigate();
  const actionData = useActionData() as SkillType;

  useEffect(() => {
    if (actionData) {
      profileCtx.setSkill(parseInt(profileId), actionData.id, actionData);
      //LocalStorageHelper.saveToLocalStorage(profileCtx.state);
      profile = profileCtx.state?.profiles.find(
        (p) => p.id === parseInt(profileId)
      );
    }
  }, [actionData]);

  useEffect(() => {
    if (!profile) {
      profileCtx.setError({
        title: "An error occurred!",
        message: "No matching profile found",
        statusCode: 400,
      });
      navigate("/admin/error");
    }
  }, [profile]);

  return (
    <Container>
      <Row className={styles.rows}>
        <h3>Edit Skills</h3>
        <p>
          Below are the skills that are attached to profile with id{" "}
          <b>{profileId}</b>
        </p>
      </Row>
      <Row className={styles.rows}>
        <SkillListForm skills={profile?.skills || []} />
      </Row>
      <Row className={styles.rows}>
        <Link to={`/admin/profile/${profileId}`}>Back To Profile</Link>
      </Row>
    </Container>
  );
};

export default EditSkillsPage;
