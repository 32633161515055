import React from "react";
import { ProfileType } from "../types/profile";
import Profile from "./Profile";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export interface ProfilesInterface {
  profiles: ProfileType[];
}

const Profiles: React.FC<ProfilesInterface> = (props) => {
  // setups
  const navigate = useNavigate();

  // handlers
  const addProfileHandler = () => {
    navigate("/admin/profile/add");
  };

  return (
    <div>
      {props.profiles && (
        <Row style={{ marginBottom: "30px", color: "#fff" }}>
          <Col>
            <h3>Current Profiles</h3>
          </Col>
          <Col>
            <Button onClick={addProfileHandler}>Add New Profile</Button>
          </Col>
        </Row>
      )}
      <Accordion>
        {props.profiles.map((profile) => (
          <Profile profile={profile} key={profile.id} />
        ))}
      </Accordion>
    </div>
  );
};

export default Profiles;
