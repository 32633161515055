import React, { ReactNode, useEffect, useReducer } from "react";
import { profileReducer } from "../reducers/profile-reducer";
import { initialState } from "../../utils/initial-state";
import { ProfileType } from "../../types/profile";
import { ACTION_TYPE } from "../actions/profile-actions";
import ProfileContext from "../../context/profile-context";
import { ErrorType } from "../../types/error";
import { LocalStorageHelper } from "../../utils/LocalStorageHelper";
import { SkillType } from "../../types/skill";
import { ProjectType } from "../../types/project";
import { ResumeType } from "../../types/resume";

export interface ICartProvider {
  children: ReactNode;
}

const ProfileProvider: React.FC<ICartProvider> = ({ children }) => {
  const localStorageState = LocalStorageHelper.loadFromLocalStorage();
  const [state, dispatchProfileAction] = useReducer(
    profileReducer,
    localStorageState !== undefined ? localStorageState : initialState
  );

  useEffect(() => {
    LocalStorageHelper.saveToLocalStorage(state);
  }, [state]);

  const addProfileHandler = (profile: ProfileType) => {
    dispatchProfileAction({
      type: ACTION_TYPE.ADD_PROFILE,
      payload: { profile: profile },
    });
  };

  const editProfileHandler = (profileId: number, updates: ProfileType) => {
    dispatchProfileAction({
      type: ACTION_TYPE.EDIT_PROFILE,
      payload: {
        updates: updates,
        profileId: profileId,
      },
    });
  };

  const setProfilesHandler = (profiles: ProfileType[]) => {
    dispatchProfileAction({
      type: ACTION_TYPE.SET_PROFILES,
      payload: {
        profiles: profiles,
      },
    });
  };

  const setActiveProfileHandler = (profile: ProfileType) => {
    dispatchProfileAction({
      type: ACTION_TYPE.SET_ACTIVE_PROFILE,
      payload: {
        profile: profile,
      },
    });
  };

  const setErrorHandler = (error: ErrorType) => {
    dispatchProfileAction({
      type: ACTION_TYPE.SET_ERROR,
      payload: {
        error: error,
      },
    });
  };

  const setSkillHandler = (
    profileId: number,
    skillId: number,
    data: SkillType
  ) => {
    dispatchProfileAction({
      type: ACTION_TYPE.SET_SKILL,
      payload: {
        profileId: profileId,
        skillId: skillId,
        skillUpdates: data,
      },
    });
  };

  const setProjectHandler = (profileId: number, data: ProjectType) => {
    dispatchProfileAction({
      type: ACTION_TYPE.SET_PROJECT,
      payload: {
        profileId: profileId,
        projectUpdates: data,
      },
    });
  };

  const setResumeHandler = (profileId: number, data: ResumeType) => {
    dispatchProfileAction({
      type: ACTION_TYPE.SET_RESUME,
      payload: {
        profileId: profileId,
        resumeUpdates: data,
      },
    });
  };

  const profileContext = {
    state: {
      profiles: state.profiles,
      activeProfile: state.activeProfile,
      theme: state.theme,
      error: state.error,
    },
    addProfile: addProfileHandler,
    editProfile: editProfileHandler,
    setProfiles: setProfilesHandler,
    setActiveProfile: setActiveProfileHandler,
    setSkill: setSkillHandler,
    setError: setErrorHandler,
    setProject: setProjectHandler,
    setResume: setResumeHandler,
  };

  return (
    <ProfileContext.Provider value={profileContext}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
