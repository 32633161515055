import React from "react";
import { UserType } from "../types/user";
import { Accordion, ListGroup } from "react-bootstrap";
import styles from "./UserComponent.module.css";

export interface UserInterface {
  user: UserType;
}

export const User: React.FC<UserInterface> = ({ user }) => {
  const status = user.active ? "Active" : "Disabled";

  return (
    <Accordion.Item eventKey={`${user.id}`} key={user.id}>
      <Accordion.Header>
        <h6>
          <span>
            {user.firstName} {user.lastName}
          </span>{" "}
          <span>updated on {new Date(user.updatedAt).toDateString()}</span>
          {"  "}
          <b>Status: {status}</b>
        </h6>
      </Accordion.Header>
      <Accordion.Body>
        <ListGroup>
          <ListGroup.Item>
            <b>Email:</b>
            {user.email}
          </ListGroup.Item>
          <ListGroup.Item>
            <b>Tokens:</b>
            <div>
              <p className={styles.wrap}>Access Token: {user.accessToken}</p>
              <p className={styles.wrap}>Refresh Token: {user.refreshToken}</p>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <b>Created At:</b> {new Date(user.createdAt).toDateString()}
          </ListGroup.Item>
          {/*<ListGroup.Item>
            <Link to={`/admin/profile/${user.id}`}>Edit Profile</Link>
          </ListGroup.Item>*/}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
