import React, { useContext, useEffect, useState } from "react";
import { Accordion, Row, Spinner } from "react-bootstrap";
import { useActionData, useNavigate, useParams } from "react-router-dom";
import ProfileForm from "../../components/ProfileForm";
import classes from "./EditProfile.module.css";
import Container from "react-bootstrap/Container";
import Skills from "../../components/Skills";
import ProjectsAccordion from "../../components/ProjectsAccordion";
import ResumesList from "../../components/ResumesList";
import ProfileContext from "../../context/profile-context";
import { ProfileType } from "../../types/profile";

const EditProfilePage: React.FC = () => {
  const { profileId } = useParams() as { profileId: string };
  const profileCtx = useContext(ProfileContext);
  const [profile, setProfile] = useState<ProfileType | undefined>(undefined);
  const navigate = useNavigate();
  const profileActionData = useActionData() as ProfileType;

  // listen for action data
  useEffect(() => {
    if (profileActionData) {
      // update context and redirect
      profileCtx.editProfile(parseInt(profileId), profileActionData);
      navigate("/admin/profile");
    }
  }, [profileActionData]);

  // locate profile
  useEffect(() => {
    const profileToEdit = profileCtx.state?.profiles.find(
      (profile) => profile.id === parseInt(profileId)
    );
    if (profileToEdit) setProfile(profileToEdit);

    if (!profileToEdit) {
      profileCtx.setError({
        title: "An Error occurred!",
        message: "No profile found matching profileId",
        statusCode: 500,
      });
      navigate("/admin/error");
    }
  }, [profile]);

  return (
    <>
      {!profile && (
        <Container fluid style={{ margin: "5% 45%" }}>
          <Row style={{ margin: "20px 0", textAlign: "center", color: "#fff" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
        </Container>
      )}
      {profile && (
        <Container style={{ margin: "20px 0 40px 0", color: "#fff" }}>
          <Row className={classes.rows}>
            <h1>Edit Profile</h1>
          </Row>
          <Accordion defaultActiveKey="profile">
            <Accordion.Item eventKey="profile">
              <Accordion.Header>Profile</Accordion.Header>
              <Accordion.Body>
                <Container>
                  <Row>
                    <ProfileForm profile={profile} method="patch" />
                  </Row>
                </Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="skills">
              <Accordion.Header>Skills</Accordion.Header>
              <Accordion.Body>
                <Row className={classes.rows}>
                  <Skills
                    skills={profile?.skills || []}
                    profileId={profileId}
                  />
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="projects">
              <Accordion.Header>Projects</Accordion.Header>
              <Accordion.Body>
                <Row className={classes.rows}>
                  <ProjectsAccordion
                    projects={profile?.projects || []}
                    profileId={profileId}
                  />
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="resumes">
              <Accordion.Header>Resumes</Accordion.Header>
              <Accordion.Body>
                <ResumesList resumes={profile?.resumes || []} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      )}
    </>
  );
};

export default EditProfilePage;
