import { useRouteError } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import styles from "./Error.module.css";

const Error = () => {
  const error: any = useRouteError();

  // defaults
  let title = "An error occurred!";
  let message = "Something went wrong!";

  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = "Not Found!";
    message = "Could not find resource or page.";
  }

  if (error.status === 401) {
    message = error.data.message;
    title = "Unauthorized!";
  }

  return (
    <Container fluid="lg" className={styles["error-container"]}>
      <Row>
        <div className={styles.oops}>Oops!</div>
        <div className={styles.wrapper}>
          <p className={styles.status}>{error.status}</p>
          <p className={styles.pipe}>|</p>
          <p className={styles.title}>{title}</p>
        </div>
        <p className={styles.message}>{message}</p>
      </Row>
    </Container>
  );
};

export default Error;
