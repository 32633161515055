import React from "react";
import { Nav, Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { SkillType } from "../../types/skill";
import { ProjectType } from "../../types/project";
import parse from "html-react-parser";
import Skills from "./Skills";
import Projects from "./Projects";

export interface MiscTabsInterface {
  about: string;
  skills: SkillType[];
  projects: ProjectType[];
}

const MiscTabs: React.FC<MiscTabsInterface> = ({ about, skills, projects }) => {
  return (
    <>
      <style>
        {`
          .nav-v2 {
          background: #f2f2f2;
          border-radius: 6px ;
      }
      .tab-container {
        margin: 40px 0;
      }

          .nav-item-v2 {
          margin: 6px 6px;
          background-color: #427D9D !important;
          border-radius: 6px !important;
      }

          .nav-item-v2 a, .nav-item-v2 a:hover {
          color: #f2f2f2;
      }
      
      .tab-content-container {
        margin: 10px 0;
        padding: 25px;
        color: #282c34;
        background: #f2f2f2;
        border-radius: 6px;
      }

          .nav-item-v2 a.active {
          background-color: #164863 !important;
      }`}
      </style>
      <Tab.Container id="left-tabs-example" defaultActiveKey="about">
        <Row>
          <Col>
            <Nav
              justify
              variant="pills"
              defaultActiveKey="about"
              className="nav-v2"
            >
              <Nav.Item className="nav-item-v2">
                <Nav.Link eventKey="about">About</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item-v2">
                <Nav.Link eventKey="skills">Skills</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item-v2">
                <Nav.Link eventKey="projects">Projects</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tab.Content className="tab-content-container">
              <Tab.Pane data-testid="misc-tab-about" eventKey="about">
                {parse(about)}
              </Tab.Pane>
              <Tab.Pane data-testid="misc-tab-skills" eventKey="skills">
                <Skills skills={skills} />
              </Tab.Pane>
              <Tab.Pane data-testid="misc-tab-projects" eventKey="projects">
                <Projects projects={projects} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default MiscTabs;
