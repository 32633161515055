import { useGetUsers } from "../../hooks/useGetUsers";
import { Row, Spinner } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useEffect } from "react";
import { Users } from "../../components/Users";

const UsersPage = () => {
  const { userErrorStatus, userResponse, userLoadingStatus, fetchUsers } =
    useGetUsers();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (userResponse) {
      console.log(userResponse);
    }
  }, [userResponse]);

  useEffect(() => {
    if (userErrorStatus) {
      console.log("Throw and error");
    }
  }, [userErrorStatus]);

  return (
    <>
      {userLoadingStatus && (
        <Container fluid style={{ margin: "5% 45%", color: "#fff" }}>
          <Row style={{ margin: "20px 0", textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
        </Container>
      )}
      {!userLoadingStatus && (
        <Container fluid style={{ margin: "5% auto", color: "#fff" }}>
          <Row>
            <h3>Current Users in our System</h3>
          </Row>
          <Row>
            <Users users={userResponse || []} />
          </Row>
        </Container>
      )}
    </>
  );
};

export default UsersPage;
