import React, { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Profiles from "../../components/Profiles";
import useGetProfiles from "../../hooks/useGetProfiles";
import ProfileContext from "../../context/profile-context";

const ProfilesPage: React.FC = () => {
  const {
    profileErrorStatus,
    profilesResponse,
    profileLoadingStatus,
    fetchProfiles,
  } = useGetProfiles();
  const profileCtx = useContext(ProfileContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfiles();
  }, []);

  useEffect(() => {
    if (profilesResponse) {
      profileCtx.setProfiles(profilesResponse);
    }
  }, [profilesResponse]);

  // any errors
  useEffect(() => {
    if (profileErrorStatus) {
      profileCtx.setError({
        title: "An Error occurred!",
        message: "Error Retrieving active profile",
        statusCode: 500,
      });
      navigate("error");
    }
  }, [profileErrorStatus]);

  return (
    <Container fluid style={{ margin: "5% auto", color: "#fff" }}>
      <Row>
        <p>
          Below you will find all current profiles that are either active or
          disabled in the admin
        </p>
        <p>
          You can edit any and we will always show the most recent active
          profile in the frontend
        </p>
      </Row>
      <Row>
        <Profiles profiles={profilesResponse || []} />
      </Row>
    </Container>
  );
};

export default ProfilesPage;
