import { useCallback, useState } from "react";
import { UserType } from "../types/user";
import UrlHelper from "../utils/UrlHelper";
import { AuthHelper } from "../utils/AuthHelper";

export const useGetUsers = () => {
  // states
  const [userResponse, setUserResponse] = useState<UserType[] | undefined>(
    undefined
  );
  const [userLoadingStatus, setUserLoadingStatus] = useState(true);
  const [userErrorStatus, setUserErrorStatus] = useState(false);

  // fetching method
  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch(`${UrlHelper.getBaseUrl()}/user`, {
        headers: {
          Authorization: "Bearer " + AuthHelper.getAuthToken(),
        },
      });

      if (response.ok) {
        setUserResponse(await response.json());
        setUserLoadingStatus(false);
      } else {
        setUserLoadingStatus(false);
        setUserErrorStatus(true);
      }
    } catch (err) {
      setUserErrorStatus(true);
      setUserLoadingStatus(false);
    }
  }, []);

  return {
    userErrorStatus,
    userResponse,
    userLoadingStatus,
    fetchUsers,
  };
};
