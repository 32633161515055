import React from "react";
import { Alert, Button, Form, Row } from "react-bootstrap";
import {
  ActionFunction,
  Form as RouterDomForm,
  json,
  redirect,
  useActionData,
  useNavigation,
} from "react-router-dom";
import UrlHelper from "../utils/UrlHelper";
import { AuthType } from "../types/auth";
import { AuthHelper } from "../utils/AuthHelper";
import styles from "./AuthForm.module.css";

const AuthForm: React.FC = () => {
  const data = useActionData() as { message: string };
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <>
      {data && data?.message && (
        <Row style={{ margin: "0 auto", width: "400px" }}>
          <Alert key={data.message} variant="danger">
            {data.message}
          </Alert>
        </Row>
      )}
      <Row
        style={{ margin: "0 auto", width: "400px" }}
        className={styles["blue-theme"]}
      >
        <Form method="post" as={RouterDomForm}>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              name="email"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" />
          </Form.Group>
          <br />
          <Button type="submit" disabled={isSubmitting} className={styles.btn}>
            {isSubmitting ? "Submitting..." : "Login"}
          </Button>
        </Form>
      </Row>
    </>
  );
};

export default AuthForm;

export const authFormAction: ActionFunction = async ({ request, params }) => {
  const formData = await request.formData();
  const method = request.method;

  // simple validation
  const userDetails = {
    email: formData.get("email"),
    password: formData.get("password"),
  };
  const url = UrlHelper.getBaseUrl() + "/admin/login";
  const response = await fetch(url, {
    method: method,
    body: JSON.stringify(userDetails),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.status === 401 || response.status === 400) {
    return response;
  }

  // any other server error
  if (!response.ok)
    throw json({ message: "Error while trying to login" }, { status: 500 });

  // returns object as {accessToken, refreshToken}
  const resData: AuthType = await response.json();

  AuthHelper.setAuthTokens(resData);

  return redirect("/admin");
};
