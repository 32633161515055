import { Row, Spinner } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import Profiles from "../../components/Profiles";
import useGetProfiles from "../../hooks/useGetProfiles";
import ProfileContext from "../../context/profile-context";

const HomePage = () => {
  const {
    profileErrorStatus,
    profilesResponse,
    profileLoadingStatus,
    fetchProfiles,
  } = useGetProfiles();
  const profileCtx = useContext(ProfileContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfiles();
  }, []);

  useEffect(() => {
    if (profilesResponse) {
      profileCtx.setProfiles(profilesResponse);
    }
  }, [profilesResponse]);

  // any errors
  useEffect(() => {
    if (profileErrorStatus) {
      profileCtx.setError({
        title: "An Error occurred!",
        message: "Error Retrieving active profile",
        statusCode: 500,
      });
      navigate("error");
    }
  }, [profileErrorStatus]);

  return (
    <>
      {profileLoadingStatus && (
        <Container fluid style={{ margin: "5% 45%" }}>
          <Row style={{ margin: "20px 0", textAlign: "center", color: "#fff" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
        </Container>
      )}
      {!profileLoadingStatus && profilesResponse && (
        <Container fluid style={{ margin: "5% auto" }}>
          <Row style={{ margin: "20px 0", textAlign: "center", color: "#fff" }}>
            <p>
              Welcome to projectsbymiguel admin. Here you will be able to adjust
              active profiles, revoke users or create new users.
            </p>
          </Row>
          <Profiles profiles={profilesResponse} />
        </Container>
      )}
    </>
  );
};

export default HomePage;
