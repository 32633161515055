import React from "react";
import { ProfileType } from "../types/profile";
import { Button, Form, FormGroup, Row } from "react-bootstrap";
import {
  ActionFunction,
  json,
  redirect,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Form as RouterDomForm } from "react-router-dom";
import UrlHelper from "../utils/UrlHelper";
import { AuthHelper } from "../utils/AuthHelper";

export interface ProfileFormInterface {
  profile: ProfileType | undefined;
  method: string;
}

const ProfileForm: React.FC<ProfileFormInterface> = ({ profile, method }) => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const isPost = method.toLowerCase() === "post";

  // handlers
  const backButtonHandler = () => {
    navigate("..");
  };

  let buttonText = isPost ? "Create Profile" : "Update Profile";

  if (isSubmitting) {
    buttonText = "Submitting...";
  }

  return (
    <Row>
      {isSubmitting && <h5>Saving...</h5>}
      {!isSubmitting && (
        <Form method={method} as={RouterDomForm} style={{ color: "#fff" }}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="name"
              placeholder="Enter name"
              defaultValue={profile ? profile.name : ""}
              name="name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicHeadline">
            <Form.Label>Headline</Form.Label>
            <Form.Control
              type="headline"
              placeholder="Enter headline"
              defaultValue={profile ? profile.headline : ""}
              name="headline"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicAbout">
            <Form.Label>About</Form.Label>
            <Form.Control
              as="textarea"
              rows={16}
              defaultValue={profile ? profile.about : ""}
              name="about"
            />
          </Form.Group>
          <FormGroup>
            <Form.Label>Status</Form.Label>
            <Form.Select
              aria-label="Status"
              defaultValue={profile ? profile.active : ""}
              name="active"
            >
              <option value="0">Disabled</option>
              <option value="1">Active</option>
            </Form.Select>
          </FormGroup>
          <br />
          <FormGroup className="mb-3">
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {buttonText}
            </Button>
            {"  "}
            <Button onClick={backButtonHandler}>Back</Button>
          </FormGroup>
        </Form>
      )}
      <div style={{ margin: "20px 0" }}>
        <Form method="delete" as={RouterDomForm}>
          <Button type="submit" className="btn btn-danger">
            Delete Profile
          </Button>
        </Form>
      </div>
    </Row>
  );
};

export default ProfileForm;

export const manipulateProfileAction: ActionFunction = async ({
  request,
  params,
}) => {
  const method = request.method;
  const formData = await request.formData();
  const token = AuthHelper.getAuthToken();
  let profileData = {
    name: formData.get("name"),
    headline: formData.get("headline"),
    about: formData.get("about"),
    active: formData.get("active") === "1",
  };

  let url = UrlHelper.getBaseUrl() + "/profile";

  if (method === "PATCH") {
    url = UrlHelper.getBaseUrl() + "/profile/" + params.profileId;
  } else if (method === "DELETE") {
    url = UrlHelper.getBaseUrl() + "/profile/" + params.profileId;
  }

  let body = JSON.stringify(profileData);

  const response = await fetch(url, {
    method: method,
    body: body,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  // check unauthorized
  if (response.status === 401) {
    throw json({ message: response.statusText }, { status: 401 });
  }

  if (response.status === 400) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: "Action could not be completed" }, { status: 500 });
  }

  // if post request redirect to profiles page
  if (method === "POST" || method === "DELETE") {
    return redirect("/admin/profile");
  }

  return response;
};
