import React from "react";
import { Alert, Button, Form, Row } from "react-bootstrap";
import {
  ActionFunction,
  Form as RouterDomForm,
  json,
  redirect,
  useActionData,
  useNavigation,
} from "react-router-dom";
import UrlHelper from "../utils/UrlHelper";
import { AuthHelper } from "../utils/AuthHelper";
import { ResumeType } from "../types/resume";

export interface ResumeFormInterface {
  method: string;
  resume: ResumeType | undefined;
}

const ResumeForm: React.FC<ResumeFormInterface> = ({ method, resume }) => {
  const data = useActionData() as { message: string[] };
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  let btnText = isSubmitting ? "Update Resume" : "Add Resume";
  if (isSubmitting) {
    btnText = "Submitting...";
  }

  return (
    <Row>
      {data && data.message && (
        <Alert variant="danger">
          {data.message.map((err) => (
            <p>{err}</p>
          ))}
        </Alert>
      )}
      <Form method={method} as={RouterDomForm} style={{ color: "#fff" }}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="url">Url</Form.Label>
          <Form.Control
            type="url"
            placeholder="Enter url"
            name="url"
            defaultValue={resume ? resume.url : ""}
          />
        </Form.Group>
        <Form.Group>
          <Button type="submit" disabled={isSubmitting}>
            {btnText}
          </Button>
        </Form.Group>
      </Form>
    </Row>
  );
};
export default ResumeForm;

export const resumeAction: ActionFunction = async ({ request, params }) => {
  const profileId = params.profileId;
  const formData = await request.formData();
  const method = request.method;
  const resumeData = {
    url: formData.get("url"),
  };
  let url = `${UrlHelper.getBaseUrl()}/profile/${profileId}/resume`;

  if (method.toLowerCase() === "patch") {
    const resumeId = params.resumeId;
    url = `${UrlHelper.getBaseUrl()}/profile/${profileId}/resume/${resumeId}`;
  }

  const token = AuthHelper.getAuthToken();
  const response = await fetch(url, {
    method: method,
    body: JSON.stringify(resumeData),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  if (response.status === 401) {
    throw json(
      { message: "Not Authorize to run this action" },
      { status: 401 }
    );
  } else if (response.status === 400) {
    return response;
  } else if (!response.ok) {
    throw json(
      { message: "Error while creating resume record" },
      { status: 5000 }
    );
  } else {
    return response;
  }
};
