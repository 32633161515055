import { useCallback, useState } from "react";
import { AuthHelper } from "../utils/AuthHelper";

const useAuthToken = () => {
  // states
  const [tokenResponse, setTokenResponse] = useState<string | null>(null);
  const [tokenLoadingStatus, setTokenLoadingStatus] = useState(true);
  const [tokenErrorStatus, setTokenErrorStatus] = useState(false);

  const fetchToken = useCallback(() => {
    const authToken = AuthHelper.getAuthToken();

    if (authToken) {
      setTokenResponse(authToken);
      setTokenLoadingStatus(false);
    } else {
      setTokenLoadingStatus(false);
      setTokenErrorStatus(true);
    }
  }, []);

  return {
    tokenErrorStatus,
    tokenResponse,
    tokenLoadingStatus,
    fetchToken,
  };
};

export default useAuthToken;
