import React from "react";
import { Row } from "react-bootstrap";
import ProfileForm from "../../components/ProfileForm";

const AddProfilePage: React.FC = () => {
  return (
    <>
      <Row style={{ margin: "2% auto", color: "#fff" }}>
        <h1>Add Profile</h1>
      </Row>
      <Row>
        <ProfileForm profile={undefined} method="post" />
      </Row>
    </>
  );
};

export default AddProfilePage;
