import React from "react";
import { ProjectType } from "../../types/project";
import parse from "html-react-parser";
import styles from "./Projests.module.css";

export interface ProjectsInterface {
  projects: ProjectType[];
}

const Projects: React.FC<ProjectsInterface> = ({ projects }) => {
  return (
    <div>
      {projects.map((project) => {
        return (
          <div key={project.id}>
            <div className={styles.project}>
              <h4>{project.title}</h4>
              <p>{parse(project.description)}</p>
              {project.url && <a href={project.url}>{project.url}</a>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Projects;
