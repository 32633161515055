import React, { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import ProjectForm from "../../components/ProjectForm";
import { useActionData, useNavigate, useParams } from "react-router-dom";
import ProfileContext from "../../context/profile-context";
import { ProjectType } from "../../types/project";

const AddProjectPage: React.FC = () => {
  const actionData = useActionData();
  const profileCtx = useContext(ProfileContext);
  const { profileId } = useParams() as { profileId: string };
  const navigate = useNavigate();

  // Added logic that will listen for Add submission data returned then save it to Context and redirect
  useEffect(() => {
    if (actionData) {
      profileCtx.setProject(parseInt(profileId), actionData as ProjectType);
      // save and redirect to profile page
      navigate(`/admin/profile/${profileId}`);
    }
  }, [actionData]);

  return (
    <Container>
      <Row style={{ color: "#fff" }}>
        <h4>Add Project to Profile</h4>
      </Row>
      <ProjectForm method="post" project={undefined} />
    </Container>
  );
};

export default AddProjectPage;
