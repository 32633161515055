import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import styles from "../pages/Error.module.css";
import { Row } from "react-bootstrap";
import ProfileContext from "../context/profile-context";

const ErrorComponent = () => {
  const profileCtx = useContext(ProfileContext);
  const currentError = profileCtx.state?.error || undefined;
  let title, message, status;
  if (currentError) {
    message = currentError.message;
    title = currentError.title;
    status = currentError.statusCode;
  } else {
    message = "Something went wrong";
    title = "An Error occurred!";
  }

  return (
    <Container fluid="lg" className={styles["error-container"]}>
      <Row>
        <div className={styles.oops}>Oops!</div>
        <div className={styles.wrapper}>
          {status && <p className={styles.status}>{status}</p>}
          <p className={styles.pipe}>|</p>
          <p className={styles.title}>{title}</p>
        </div>
        <p className={styles.message}>{message}</p>
      </Row>
    </Container>
  );
};

export default ErrorComponent;
