import { AuthType } from "../types/auth";

export class AuthHelper {
  /**
   * Constructor
   * @private
   */
  private constructor() {
    // no constructor instantiations allowed
  }

  /**
   * Get Auth Token
   * @return string | null
   */
  public static getAuthToken(): string | null {
    // pull from localStorage
    const token = localStorage.getItem("access");

    // no token return null
    if (!token) return null;

    // check duration
    const tokenDuration = AuthHelper.getTokenDuration();

    if (tokenDuration < 0) {
      return "EXPIRED";
    }

    return token;
  }

  /**
   * Get Token Duration
   * @return number
   */
  public static getTokenDuration(): number {
    const storedTokenDuration = localStorage.getItem("duration") || "";
    const expirationDate = new Date(storedTokenDuration);
    const now = new Date();
    return expirationDate.getTime() - now.getTime();
  }

  /**
   * Token Loader function.
   * @return string | null
   */
  public static tokenLoader(): string | null {
    return AuthHelper.getAuthToken();
  }

  /**
   * Set Auth Tokens
   * @param tokens
   * @return void
   */
  public static setAuthTokens(tokens: AuthType): void {
    // expiration is 2hrs
    localStorage.setItem("access", tokens.accessToken);
    localStorage.setItem("refresh", tokens.refreshToken);
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 2);
    localStorage.setItem("duration", expiration.toISOString());
  }

  /**
   * Remove Auth tokens
   * @return void
   */
  public static removeAuthTokens(): void {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("duration");
  }
}
