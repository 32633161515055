import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "../components/UI/Page";
import Picture from "../components/Main/Picture";
import Socials from "../components/Main/Socials";
import Documents from "../components/Main/Documents";
import MiscTabs from "../components/Main/MiscTabs";
import ProfileContext from "../context/profile-context";
import useActiveProfile from "../hooks/useActiveProfile";
import Container from "react-bootstrap/Container";
import { Row, Spinner } from "react-bootstrap";

const MainHomePage: React.FC = () => {
  const {
    activeProfileErrorStatus,
    activeProfileResponse,
    activeProfileLoadingStatus,
    fetchActiveProfile,
  } = useActiveProfile();
  const profileCtx = useContext(ProfileContext);
  const navigate = useNavigate();

  // fetch the active profile
  useEffect(() => {
    fetchActiveProfile();
  }, []);

  // on activeProfileResponse change run effect
  useEffect(() => {
    if (activeProfileResponse) {
      profileCtx.setActiveProfile(activeProfileResponse);
    }
  }, [activeProfileResponse]);

  // any error navigate to error page
  useEffect(() => {
    if (activeProfileErrorStatus) {
      profileCtx.setError({
        title: "An Error occurred!",
        message: "Error Retrieving active profile",
        statusCode: 500,
      });
      navigate("error");
    }
  }, [activeProfileErrorStatus]);

  return (
    <>
      {!activeProfileErrorStatus && activeProfileResponse && (
        <PageLayout>
          <Picture />
          <Socials
            name={activeProfileResponse.name}
            headline={activeProfileResponse.headline}
          />
          <Documents resumes={activeProfileResponse.resumes} />
          <MiscTabs
            about={activeProfileResponse.about}
            skills={activeProfileResponse.skills}
            projects={activeProfileResponse.projects}
          />
        </PageLayout>
      )}
      {activeProfileLoadingStatus && (
        <PageLayout>
          <Container fluid style={{ margin: "5% 45%" }}>
            <Row style={{ margin: "20px 0", textAlign: "center" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Row>
          </Container>
        </PageLayout>
      )}
    </>
  );
};

export default MainHomePage;
