import React from "react";
import { SkillType } from "../types/skill";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import styles from "./SkillsComponent.module.css";
import { NavLink } from "react-router-dom";
import SkillsList from "./SkillsList";

export interface SkillWithProfileInterface {
  skills: SkillType[];
  profileId: string | null;
}

const Skills: React.FC<{
  skills: SkillType[];
  profileId: string | null;
}> = ({ skills, profileId }) => {
  return (
    <Container className={styles.row}>
      <SkillsList skills={skills} />
      {skills.length > 0 && (
        <Row className={styles.row}>
          <NavLink
            className="btn btn-primary"
            to={`/admin/profile/${profileId}/skill`}
          >
            Edit Skills
          </NavLink>
        </Row>
      )}
      {skills.length === 0 && (
        <div className={styles.row}>
          <NavLink
            className="btn btn-primary"
            to={`/admin/profile/${profileId}/skill`}
          >
            Add Skills
          </NavLink>
        </div>
      )}
    </Container>
  );
};

export default Skills;
