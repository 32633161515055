import { initialState } from "../utils/initial-state";
import React from "react";
import { ProfileType } from "../types/profile";
import { ErrorType } from "../types/error";
import { LocalStorageHelper } from "../utils/LocalStorageHelper";
import { SkillType } from "../types/skill";
import { ProjectType } from "../types/project";
import { ResumeType } from "../types/resume";

// Context
const state = LocalStorageHelper.loadFromLocalStorage();
const ProfileContext = React.createContext({
  state: state ? state : initialState,

  // profile available methods
  addProfile: (profile: ProfileType) => {},
  editProfile: (profileId: number, profileUpdates: ProfileType) => {},
  //getProfile: (profileId: number) => {},
  setProfiles: (profiles: any) => {},

  // active profile methods
  //getActiveProfile: () => {},
  setActiveProfile: (profile: ProfileType) => {},

  // project available methods
  setProject: (profileId: number, data: ProjectType) => {},
  // skills available methods
  setSkill: (profileId: number, skillId: number, data: SkillType) => {},
  // resume available methods
  setResume: (profileId: number, data: ResumeType) => {},
  // error methods
  setError: (error: ErrorType) => {},
});
export default ProfileContext;
