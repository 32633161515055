import { ProfileType } from "../../types/profile";
import { ErrorType } from "../../types/error";
import { SkillType } from "../../types/skill";
import { ProjectType } from "../../types/project";
import { ResumeType } from "../../types/resume";

export interface IActions {
  type: string;
  payload: {
    profile?: ProfileType;
    profileId?: number;
    updates?: ProfileType;
    profiles?: ProfileType[];
    error?: ErrorType | undefined;
    skillId?: number;
    skillUpdates?: SkillType;
    projectUpdates?: ProjectType;
    resumeUpdates?: ResumeType;
  };
}

export enum ACTION_TYPE {
  ADD_PROFILE = "ADD_PROFILE",
  EDIT_PROFILE = "EDIT_PROFILE",
  SET_PROFILES = "SET_PROFILES",
  SET_ACTIVE_PROFILE = "SET_ACTIVE_PROFILE",
  SET_SKILL = "SET_SKILL",
  SET_ERROR = "SET_ERROR",
  SET_PROJECT = "SET_PROJECT",
  SET_RESUME = "SET_RESUME",
}

export const setError = (error: ErrorType) => {
  return { type: ACTION_TYPE.SET_ERROR, payload: { error: error } };
};

export const addProfile = (profile: ProfileType) => {
  return { type: ACTION_TYPE.ADD_PROFILE, payload: { profile: profile } };
};

export const editProfile = (profileId: number, profileUpdates: ProfileType) => {
  return {
    type: ACTION_TYPE.EDIT_PROFILE,
    payload: {
      profileId: profileId,
      updates: profileUpdates,
    },
  };
};

export const setActiveProfile = (profile: ProfileType) => {
  return {
    type: ACTION_TYPE.SET_ACTIVE_PROFILE,
    payload: {
      profile: profile,
    },
  };
};

export const setProfiles = (profiles: ProfileType[]) => {
  return { type: ACTION_TYPE.SET_PROFILES, payload: { profiles: profiles } };
};

export const setSkill = (
  profileId: number,
  skillId: number,
  data: SkillType
) => {
  return {
    type: ACTION_TYPE.SET_SKILL,
    payload: {
      profileId: profileId,
      skillId: skillId,
      skillUpdates: data,
    },
  };
};

export const setProject = (profileId: number, project: ProjectType) => {
  return {
    type: ACTION_TYPE.SET_PROJECT,
    payload: {
      profileId: profileId,
      projectUpdates: project,
    },
  };
};

export const setResume = (profileId: number, resume: ResumeType) => {
  return {
    type: ACTION_TYPE.SET_RESUME,
    payload: { profileId: profileId, resumeUpdates: resume },
  };
};
